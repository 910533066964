.nav-bar {
  background: #181818;
  width: 60px;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: 3;
  min-height: 500px;

  .logo {
    display: block;
    padding: 8px 0;

    img {
      display: block;
      margin: 8px auto;
      width: 24px;
      height: auto;

      &.sub-logo {
        width: 44px;
      }
    }
  }

  nav {
    display: block;
    text-align: center;
    position: absolute;
    height: 210px;
    top: 50%;
    margin-top: -120px;
    width: 100%;

    a {
      font-size: 22px;
      color: #4d4d4e;
      display: block;
      line-height: 51px;
      height: 51px;
      position: relative;
      text-decoration: none;

      i {
        transition: all 0.3s ease-out;
      }

      &:hover {
        color: #ffd700;

        svg {
          opacity: 0;
        }

        &:after {
          opacity: 1;
        }
      }

      &:after {
        content: '';
        font-size: 9px;
        letter-spacing: 2px;
        position: absolute;
        bottom: 0;
        display: block;
        width: 100%;
        text-align: center;
        opacity: 0;
        -webkit-transition: all 0.3s ease-out;
        transition: all 0.3s ease-out;
      }

      &:first-child {
        &:after {
          content: 'HOME';
        }
      }
    }

    a.about-link {
      &:after {
        content: 'ABOUT';
      }
    }

    a.contact-link {
      &:after {
        content: 'CONTACT';
      }
    }

    a.portfolio-link {
      &:after {
        content: 'PORTFOLIO';
      }
    }

    a.active {
      svg {
        color: #ffd700;
      }
    }
  }

  ul {
    position: absolute;
    bottom: 20px;
    width: 100%;
    display: block;
    padding: 0;
    list-style: none;
    text-align: center;
    margin: 0;

    li {
      a {
        padding: 7px 0;
        display: block;
        font-size: 15px;
        line-height: 16px;
        color: #4d4d4e;

        &:hover svg {
          color: #ffd700;
        }
      }
    }
  }

  .hamburger-icon {
    display: none;
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
    font-size: 24px;

    svg {
      width: 1.5em;
      height: 1.5em;
    }
  }
}

@media screen and (max-width: 1200px) {
  .nav-bar {
    background: transparent;
    position: initial;
    height: auto;
    min-height: auto;

    .logo {
      background: transparent;
    }

    nav {
      width: 60%;
      top: 0;
      right: 0;
      background: #181818;
      height: 100%;
      margin: 0;
      z-index: 2;
      position: fixed;
      transform: translateX(100%);
      transition: transform 0.3s ease-out;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      &.mobile-show {
        transform: translateX(0);
      }

      a {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 10px 0; // Add spacing between nav links
        font-size: 22px;
        color: #4d4d4e;

        &:hover {
          color: #ffd700;
        }

        &:after {
          opacity: 1;
          position: initial;
          width: auto;
          margin-left: 10px;
        }

        svg {
          opacity: 1 !important;
        }
      }
    }

    ul {
      position: fixed;
      bottom: 20%;
      right: 0;
      width: 60%;
      background: #181818;
      z-index: 2;
      transform: translateX(100%);
      transition: transform 0.3s ease-out;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;
      margin: 0;
      list-style: none;

      &.mobile-show {
        transform: translateX(0);
      }

      li {
        margin: 0 10px; // Add spacing between social media icons

        a {
          display: block;
          font-size: 15px;
          line-height: 16px;
          color: #4d4d4e;

          &:hover svg {
            color: #ffd700;
          }
        }
      }
    }

    .hamburger-icon {
      display: block;
      z-index: 3;
      position: fixed;
      top: 15px;
      right: 15px;
      cursor: pointer;
      font-size: 24px;

      svg {
        width: 1.5em;
        height: 1.5em;
      }
    }
  }
}