.home-page {
  .text-zone {
    position: absolute;
    left: 10%;
    top: 50%;
    transform: translateY(-50%);
    width: 40%;
    max-height: 90%;
  }


  h1 {
    color: #fff;
    font-size: 53px;
    margin: 0;
    font-family: 'Coolvetica';
    font-weight: 400;

    &::before {
      content: '<h1>';
      font-family: 'La Belle Aurore';
      color: #ffd700;
      font-size: 18px;
      position: absolute;
      margin-top: -40px;
      left: 15px;
      opacity: 0.6;
    }

    &::after {
      content: '</h1>';
      font-family: 'La Belle Aurore';
      color: #ffd700;
      font-size: 18px;
      position: absolute;
      margin-top: 18px;
      margin-left: 15px;
      opacity: 0.6;
      animation:  fadeIn 1s 1.7s backwards;
    }


    img {
      width: 40px;
      margin-left: 15px;
      opacity: 0;
      height: auto;
      animation: rotateIn 1s linear both;
      animation-delay: 1.4s;
    }
  }

  h2 { 
    color: #8d8d8d;
    margin-top: 20px;
    font-weight: 400;
    font-size: 11px;
    font-family: sans-serif;
    letter-spacing: 3px;
    animation: fadein 1s 1.8s backwards;
  }

  .flat-button {
    color: #ffd700;
    font-size: 13px;
    font-weight: 400;
    letter-spacing: 4px;
    font-family: sans-serif;
    text-decoration: none;
    padding: 10px 18px;
    border: 1px solid #ffd700;
    margin-top: 25px;
    float: left;
    animation: fadeIn 1s 2s backwards;
    white-space: nowrap;

    &:hover {
      background: #ffd700;
      color: #000;
    }
  }  

  .construction-banner {
    background-color: #ffcc00; /* Bright yellow background */
    color: #333; /* Dark text color for contrast */
    padding: 15px 0; /* Padding for the banner */
    text-align: center; /* Center text horizontally */
    font-size: 1.5rem; /* Set a clear size for the banner text */
    position: relative; /* To keep it above other content */
    z-index: 1000; /* Ensure it stays on top of other elements */
    width: 100%; /* Full width of the screen */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Optional shadow */
    margin-bottom: 20px; /* Space between the banner and the content below */
    
    /* Resetting styles to avoid inheritance issues */
    display: block; /* Ensures it takes up the full width */
    border: none; /* Ensures no default border */
    overflow: visible; /* Ensure banner is visible */
  } 
  
  
}

@media screen and (max-width: 1200px){
  .home-page {
    height: 90%;
    .text-zone {
      position: relative;
      left: 10%;
      top: 50%;      
      width: 90%;
      height: 73%;
      margin-bottom: 0;
      h1{
        font-size: 5vh;
      } 
      h2{
        font-size: 2rem;
      }
      .flat-button {
        padding: auto;
      }
    }
    
  }
} 

