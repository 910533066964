.container.about-page {
    width: 100%;
    height: 100vh;
    position: relative;
    overflow-y: auto;
    opacity: 0;
    z-index: 1;
    transform-style: preserve-3d;
    animation: fadeIn 1s forwards;
    animation-delay: 1s;
    padding: 5% 0;
    
    .content-wrapper {
      min-height: 100%;
      position: relative;
    }
  }
  
  .resume-section {
    margin: 30px 0;
    animation: fadeIn 1s 1.8s backwards;
  
    .resume-button {
      background: transparent;
      border: 2px solid #ffd700;
      color: #ffd700;
      padding: 10px 20px;
      font-size: 16px;
      font-family: 'Coolvetica';
      letter-spacing: 2px;
      cursor: pointer;
      position: relative;
      transition: all 0.3s ease-in-out;
      display: inline-flex;
      align-items: center;
      gap: 10px;
  
      svg {
        font-size: 18px;
      }
  
      &:hover {
        background: #ffd700;
        color: #1a2b38;
        transform: translateY(-2px);
      }
  
      &:active {
        transform: translateY(0);
      }
    }
  }
  
  .text-zone {
    position: relative;
    left: 10%;
    width: 35%;
    padding-bottom: 100px; // Add space for scrolling
  
    h1 {
      font-size: 53px;
      font-family: 'Coolvetica';
      color: #ffd700;
      font-weight: 400;
      margin-top: 0;
      position: relative;
      margin-bottom: 40px;
      left: 10px;
  
      &::before {
        content: '<h1>';
        font-family: 'La Belle Aurore';
        font-size: 18px;
        position: absolute;
        margin-top: -10px;
        left: -10px;
        opacity: 0.6;
        line-height: 18px;
      }
  
      &::after {
        content: '</h1>';
        font-family: 'La Belle Aurore';
        font-size: 18px;
        line-height: 18px;
        position: absolute;
        left: -30px;
        bottom: -20px;
        margin-left: 20px;
        opacity: 0.6;
      }
    }
  
    h2 {
      color: #ffd700;
      font-size: 40px;
      margin-top: 40px;
      font-weight: 400;
      font-family: 'Coolvetica';
      letter-spacing: 3px;
      animation: fadeIn 1s 1.8s backwards;
    }
  
    p {
      font-size: 13px;
      color: #fff;
      font-family: sans-serif;
      font-weight: 300;
      max-width: fit-content;
      animation: pulse 1s;
      
      &:nth-of-type(1) {
        animation-delay: 1.1s;
      }
      &:nth-of-type(2) {
        animation-delay: 1.2s;
      }
      &:nth-of-type(3) {
        animation-delay: 1.3s;
      }
    }
  }
  
  .skills-list {
    margin-top: 30px;
    animation: fadeIn 1s 1.8s backwards;
  
    .skill-item {
      background: rgba(255, 255, 255, 0.05);
      padding: 20px;
      border-radius: 4px;
      margin-bottom: 20px;
      transition: all 0.3s ease;
  
      &:hover {
        background: rgba(255, 255, 255, 0.1);
        transform: translateX(10px);
      }
  
      .tech-icon {
        font-size: 30px;
        margin-bottom: 10px;
        display: inline-block;
      }
  
      h3 {
        color: #ffd700;
        font-size: 18px;
        margin-bottom: 10px;
        font-family: 'Coolvetica';
      }
  
      p {
        margin: 0;
        animation: none;
      }
    }
  }
  
  .stage-cube-cont {
    width: 50%;
    height: 100%;
    top: 0;
    padding-top: 18%;
    margin-left: 0;
    position: fixed;
    right: 0;
    overflow: hidden;
  }
  
  .cubespinner {
    animation-name: spincube;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-duration: 12s;
    transform-style: preserve-3d;
    transform-origin: 100px 100px 0;
    margin-left: calc(50% - 100px);
  
    div {
      position: absolute;
      width: 200px;
      height: 200px;
      border: 1px solid #ccc;
      background: rgba(255, 255, 255, 0.4);
      text-align: center;
      font-size: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: 0 0 20px 0px lightyellow;
    }
  
    .face1 { transform: translateZ(100px); }
    .face2 { transform: rotateY(90deg) translateZ(100px); }
    .face3 { transform: rotateY(90deg) rotateX(90deg) translateZ(100px); }
    .face4 { transform: rotateY(180deg) rotateZ(90deg) translateZ(100px); }
    .face5 { transform: rotateY(-90deg) rotateZ(90deg) translateZ(100px); }
    .face6 { transform: rotateX(-90deg) translateZ(100px); }
  }
  
  @keyframes spincube {
    from,
    to {
      transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg);
    }
    16% {
      transform: rotateY(-90deg);
    }
    33% {
      transform: rotateY(-90deg) rotateX(90deg);
    }
    50% {
      transform: rotateY(-180deg) rotateZ(90deg);
    }
    66% {
      transform: rotateY(-270deg) rotateX(90deg);
    }
    83% {
      transform: rotateX(90deg);
    }
  }
  
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  
  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }
  
  @media screen and (max-width: 1200px) {
    .container.about-page {
      padding: 20px;
      overflow-y: auto;
  
      .text-zone {
        position: relative;
        left: 0;
        width: 100%;
        padding: 20px;
        box-sizing: border-box;
      }
    }
  
    .stage-cube-cont {
      position: relative;
      width: 100%;
      height: 300px;
      overflow: visible;
      padding-top: 50px;
      margin-top: 50px;
    }
  }